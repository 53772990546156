import React from "react";
import xhr from "superagent";
import async from "async";
import utils from "../utils/utils.js";

const awscheck = [
  { service: "sqs", region: "us-west-2" },
  { service: "s3", region: "us-west-1" },
  { service: "ec2", region: "us-west-1" },
  { service: "ec2", region: "ap-southeast-1" },
  { service: "ec2", region: "eu-central-1" },
];

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const fetchInitData = (callback) => {
  const state = {};
  // fetch1
  async.parallel(
    [
      //aws
      function (cbp) {
        let awsStatus = [];
        async.each(
          awscheck,
          function (awsc, cb) {
            xhr
              .get("/apiv1/status/aws/" + awsc.service + "/" + awsc.region)
              .then(function (res) {
                let status = res.body;
                awsStatus.push({
                  title: awsc.service + "-" + awsc.region,
                  status: status.status,
                  message: status.detail,
                });
                cb(null);
              });
          },
          function (err) {
            if (err) {
              cbp({
                code: 400,
                message: "can not check service",
                responseType: "json",
              });
            } else {
              state.awsStatus = awsStatus;
              cbp(null, "awsStatus");
            }
          }
        );
      },
      //firebase
      function (cbp) {
        xhr.get("/apiv1/status/firebase", (err, res) => {
          if (err) {
            state.firebaseStatus = "CRITICAL";
            state.firebaseMessage = "API Timeout";
          } else {
            let status = res.body;
            state.firebaseStatus = status.status;
            state.firebaseMessage = status.detail;
          }
          cbp(null, "firebase");
        });
      },

      //singular App server
      function (cbp) {
        xhr.get("/apiv1/status/singular/app", (err, res) => {
          if (err) {
            state.singularAppStatus = "CRITICAL";
            state.singularAppMessage = "API Timeout";
          } else {
            let status = res.body;
            state.singularAppStatus = status.status;
            state.singularAppMessage = status.detail;
          }
          cbp(null, "singularApp");
        });
      },
      //cloudflare
      function (cbp) {
        xhr.get("/apiv1/status/cloudflare", (err, res) => {
          if (err) {
            console.error(err);
            state.cloudflareStatus = "CRITICAL";
            state.cloudflareMessage = "API Timeout";
          } else {
            let statusObj = res.body;
            // when api got an error it will return a status message at the obj.status field
            let statusMessage = statusObj.status || "";

            if (statusMessage == "CRITICAL") {
              console.error(statusObj);
              state.cloudflareStatus = statusMessage;
              state.cloudflareMessage = statusObj.detail || "Unknow Detail";
            } else {
              state.cloudflareStatus = statusObj;
            }
          }

          cbp(null, "cloudflare");
        });
      },
    ],
    (err, result) => {
      state.fetched1 = true;
      if (err) callback(err);
      else callback(null, state);
    }
  );
  // fetch2
  async.parallel(
    [
      // //singular alpha server
      // function (cbp) {
      //   xhr.get("/apiv1/status/singular/alpha", (err, res) => {
      //     if (err) {
      //       state.singularAlphaStatus = "CRITICAL";
      //       state.singularAlphaMessage = "API Timeout";
      //     } else {
      //       let status = res.body;
      //       state.singularAlphaStatus = status.status;
      //       state.singularAlphaMessage = status.detail;
      //     }
      //     cbp(null, "singularAlpha");
      //   });
      // },
      //singular Beta server
      function (cbp) {
        xhr.get("/apiv1/status/singular/beta", (err, res) => {
          if (err) {
            state.singularBetaStatus = "CRITICAL";
            state.singularBetaMessage = "API Timeout";
          } else {
            let status = res.body;
            state.singularBetaStatus = status.status;
            state.singularBetaMessage = status.detail;
          }
          cbp(null, "singularBeta");
        });
      },
      //data stream
      function (cbp) {
        xhr.get("/apiv1/status/datastream", (err, res) => {
          if (err) {
            state.dataStreamStatus = "CRITICAL";
            state.dataStreamMessage = "API Timeout";
          } else {
            let status = res.body;
            state.dataStreamStatus = status.status;
            state.dataStreamMessage = status.detail;
          }
          cbp(null, "datastream");
        });
      },
      //data store
      function (cbp) {
        xhr.get("/apiv1/status/datastore", (err, res) => {
          if (err) {
            state.dataStoreStatus = "CRITICAL";
            state.dataStoreMessage = "API Timeout";
          } else {
            let status = res.body;
            state.dataStoreStatus = status.status;
            state.dataStoreMessage = status.detail;
          }
          cbp(null, "datastore");
        });
      },
    ],
    (err, result) => {
      state.fetched2 = true;
      if (err) callback(err);
      else callback(null, state);
    }
  );
};

class SingularStatus extends React.Component {
  constructor(props) {
    super(props);
    this.testWriteBegin = null;
    this.testWriteId = getRandomInt(0, 1000000);
    this.testWriteCount = 0;
    this.measureFirebaseSpeedTimeoutHandler = null;

    this.state = {
      busyAll: true,

      awsStatus: null,
      showAwsDetails: false,

      cloudflareStatus: null,
      showCloudFlareServicesDetails: false,

      firebaseStatus: null,
      firebaseMessage: null,
      firebaseWriteTestPending: false,
      firebaseSpeed: 0,

      singularLocalStatus: null,
      singularLocalMessage: null,
      singularAlphaStatus: null,
      singularAlphaMessage: null,
      singularBetaStatus: null,
      singularBetaMessage: null,
      singularAppStatus: null,
      singularAppMessage: null,
      dataStreamStatus: null,
      dataStreamMessage: null,
      dataStoreStatus: null,
      dataStoreMessage: null,
    };
  }

  componentDidMount() {
    fetchInitData((err, state) => {
      if (state.fetched1 && state.fetched2) state.busyAll = false;
      if (err == null) {
        this.setState({ ...this.state, ...state });
      }
    });

    this.props.rtdbRef
      .child("status_singular_live/testwrite")
      .child(this.testWriteId)
      .on("value", (snapshot) => {
        if (this.testWriteBegin != null) {
          this.setState({
            firebaseSpeed: new Date() - this.testWriteBegin,
            firebaseWriteTestPending: false,
          });
        } else {
          this.setState({
            firebaseWriteTestPending: false,
          });
        }
        this.testWriteCount++;
      });

    const measureFirebaseSpeed = () => {
      this.testWriteBegin = new Date();
      this.setState({
        firebaseWriteTestPending: true,
      });
      xhr
        .put("/apiv1/status/firebase/write")
        .send({ id: this.testWriteId })
        .end();
    };

    const realtimeMeasureFirebaseSpeed = () => {
      measureFirebaseSpeed();

      clearTimeout(this.measureFirebaseSpeedTimeoutHandler);
      this.measureFirebaseSpeedTimeoutHandler = setTimeout(() => {
        realtimeMeasureFirebaseSpeed();
      }, 10000);
    };

    realtimeMeasureFirebaseSpeed();
  }

  componentWillUnmount() {
    clearTimeout(this.measureFirebaseSpeedTimeoutHandler);
  }

  render() {
    let singularAppLink = (
      <a href="https://app.singular.live" target="_blank" rel="noreferrer">
        Singular App Server
      </a>
    );
    let singularBetaLink = (
      <a href="https://beta.singular.live" target="_blank" rel="noreferrer">
        Singular Beta Server
      </a>
    );
    // let singularAlphaLink = (
    //   <a href="https://alpha.singular.live" target="_blank" rel="noreferrer">
    //     Singular Alpha Server
    //   </a>
    // );
    let firebaseLink = (
      <a
        href="https://status.firebase.google.com"
        target="_blank"
        rel="noreferrer"
      >
        Realtime Database - Firebase
      </a>
    );
    let dataStreamLink = (
      <a
        href="https://datastream.singular.live"
        target="_blank"
        rel="noreferrer"
      >
        Singular Data Stream
      </a>
    );
    let amazonS3Link = (
      <a href="https://status.aws.amazon.com" target="_blank" rel="noreferrer">
        Media Storage - Amazon S3
      </a>
    );
    let cloudflareLink = (
      <a
        href="https://www.cloudflarestatus.com"
        target="_blank"
        rel="noreferrer"
      >
        CDN - Cloudflare
      </a>
    );

    let globalStatus = "success";
    if (this.state.busyAll) {
      globalStatus = "pending";
    }

    let awsStatus = this.state.awsStatus;
    let mediaStorageStatus;
    if (awsStatus === null) {
      mediaStorageStatus = (
        <div className="status-row">
          <div className="r-service-name">{amazonS3Link}</div>
          <div className="r-service-status">
            <i className="fa fa-circle-o-notch fa-spin fa-fw pending"></i>
          </div>
          <div className="r-service-message"></div>
        </div>
      );
    } else {
      mediaStorageStatus = awsStatus.map(function (item, index) {
        if (item.title === "s3-us-west-1") {
          var statusStyle = {};
          var statusClass = "pending";
          if (item.status !== "OK") {
            statusStyle.color = "red";
            statusClass = "error";
            globalStatus = "error";
          } else {
            item.message = "";
            statusClass = "success";
          }
          return (
            <div className="status-row" key={index}>
              <div className="r-service-name">{amazonS3Link}</div>
              <div className="r-service-status">
                <i
                  className={`fa fa-circle ${statusClass}`}
                  style={statusStyle}
                ></i>
              </div>
              <div className="r-service-message">{item.message}</div>
            </div>
          );
        }
        return <div key={index} />;
      });
    }

    let aws;
    if (awsStatus && this.state.showAwsDetails === true) {
      aws = awsStatus.map(function (item, index) {
        var statusClass = "pending";
        if (item.status !== "OK") {
          statusClass = "error";
        } else {
          item.message = "";
          statusClass = "success";
        }
        return (
          <div className="status-row" key={index}>
            <div className="r-service-name">{item.title}</div>
            <div className="r-service-status">
              <i className={`fa fa-circle ${statusClass}`}></i>
            </div>
            <div className="r-service-message">{item.message}</div>
          </div>
        );
      });
    }
    let awsHeaderStatus = "pending";
    if (awsStatus) {
      let result = awsStatus.find(function (item, index) {
        if (item.status !== "OK") {
          return item;
        }
        return null;
      });
      if (result) {
        awsHeaderStatus = "error";
      } else {
        awsHeaderStatus = "success";
      }
    }

    let dataStream;
    if (this.state.dataStreamStatus === null) {
      dataStream = (
        <div className="status-row">
          <div className="r-service-name">{dataStreamLink}</div>
          <div className="r-service-status">
            <i className="fa fa-circle-o-notch fa-spin fa-fw pending"></i>
          </div>
          <div className="r-service-message"></div>
        </div>
      );
    } else {
      if (this.state.dataStreamStatus === "OK") {
        dataStream = (
          <div className="status-row">
            <div className="r-service-name">{dataStreamLink}</div>
            <div className="r-service-status">
              <i className="fa fa-circle success"></i>
            </div>
            <div className="r-service-message"></div>
          </div>
        );
      } else {
        dataStream = (
          <div className="status-row">
            <div className="r-service-name">{dataStreamLink}</div>
            <div className="r-service-status">
              <i className="fa fa-circle error" style={{ color: "red" }}></i>
            </div>
            <div className="r-service-message">
              {this.state.dataStreamStatus} - {this.state.dataStreamMessage}
            </div>
          </div>
        );
      }
    }

    let dataStore;
    if (this.state.dataStoreStatus === null) {
      dataStore = (
        <div className="status-row">
          <div className="r-service-name">Singular Data Store</div>
          <div className="r-service-status">
            <i className="fa fa-circle-o-notch fa-spin fa-fw pending"></i>
          </div>
          <div className="r-service-message"></div>
        </div>
      );
    } else {
      if (this.state.dataStoreStatus === "OK") {
        dataStore = (
          <div className="status-row">
            <div className="r-service-name">Singular Data Store</div>
            <div className="r-service-status">
              <i className="fa fa-circle success"></i>
            </div>
            <div className="r-service-message"></div>
          </div>
        );
      } else {
        dataStore = (
          <div className="status-row">
            <div className="r-service-name">Singular Data Store</div>
            <div className="r-service-status">
              <i className="fa fa-circle error" style={{ color: "red" }}></i>
            </div>
            <div className="r-service-message">
              {this.state.dataStoreStatus} - {this.state.dataStoreMessage}
            </div>
          </div>
        );
      }
    }

    let firebase;
    if (this.state.firebaseStatus === null) {
      firebase = (
        <div className="status-row">
          <div className="r-service-name">{firebaseLink}</div>
          <div className="r-service-status">
            <i className="fa fa-circle-o-notch fa-spin fa-fw pending"></i>
          </div>
          <div className="r-service-message"></div>
        </div>
      );
    } else {
      if (this.state.firebaseStatus === "OK") {
        let firebaseSpeed;
        let speed = this.state.firebaseSpeed;
        if (this.state.firebaseWriteTestPending === true || speed === 0) {
          firebaseSpeed = (
            <span style={{ marginLeft: "40%" }}>
              <i className="fa fa-tachometer pending"></i>
              <i
                className="fa fa-circle-o-notch fa-spin fa-fw pending"
                style={{ marginLeft: "10px" }}
              ></i>
            </span>
          );
        } else {
          let speedClass = "success";
          if (speed > 5000) {
            speedClass = "error";
          } else if (speed > 2000) {
            speedClass = "warning";
          }

          firebaseSpeed = (
            <span style={{ marginLeft: "40%" }}>
              <i className={"fa fa-tachometer " + speedClass}></i>
              <span style={{ marginLeft: "10px" }}>
                {new Intl.NumberFormat().format(speed) + " ms"}
              </span>
            </span>
          );
        }
        firebase = (
          <div className="status-row">
            <div className="r-service-name">{firebaseLink}</div>
            <div className="r-service-status">
              <i className="fa fa-circle success"></i>
            </div>
            <div className="r-service-message">{firebaseSpeed}</div>
          </div>
        );
      } else {
        globalStatus = "error";
        firebase = (
          <div className="status-row">
            <div className="r-service-name">{firebaseLink}</div>
            <div className="r-service-status">
              <i className="fa fa-circle error"></i>
            </div>
            <div className="r-service-message">
              {this.state.firebaseStatus} - {this.state.firebaseMessage}
            </div>
          </div>
        );
      }
    }

    // let singularAlphaServer;
    // if (this.state.singularAlphaStatus === null) {
    //   singularAlphaServer = (
    //     <div className="status-row">
    //       <div className="r-service-name">{singularAlphaLink}</div>
    //       <div className="r-service-status">
    //         <i className="fa fa-circle-o-notch fa-spin fa-fw pending"></i>
    //       </div>
    //       <div className="r-service-message"></div>
    //     </div>
    //   );
    // } else {
    //   if (this.state.singularAlphaStatus === "OK") {
    //     singularAlphaServer = (
    //       <div className="status-row">
    //         <div className="r-service-name">{singularAlphaLink}</div>
    //         <div className="r-service-status">
    //           <i className="fa fa-circle success"></i>
    //         </div>
    //         <div className="r-service-message"></div>
    //       </div>
    //     );
    //   } else {
    //     singularAlphaServer = (
    //       <div className="status-row">
    //         <div className="r-service-name">{singularAlphaLink}</div>
    //         <div className="r-service-status">
    //           <i className="fa fa-circle error" style={{ color: "red" }}></i>
    //         </div>
    //         <div className="r-service-message">
    //           {this.state.singularAlphaStatus} -{" "}
    //           {this.state.singularAlphaMessage}
    //         </div>
    //       </div>
    //     );
    //   }
    // }

    let singularBetaServer;
    if (this.state.singularBetaStatus === null) {
      singularBetaServer = (
        <div className="status-row">
          <div className="r-service-name">{singularBetaLink}</div>
          <div className="r-service-status">
            <i className="fa fa-circle-o-notch fa-spin fa-fw pending"></i>
          </div>
          <div className="r-service-message"></div>
        </div>
      );
    } else {
      if (this.state.singularBetaStatus === "OK") {
        singularBetaServer = (
          <div className="status-row">
            <div className="r-service-name">{singularBetaLink}</div>
            <div className="r-service-status">
              <i className="fa fa-circle success"></i>
            </div>
            <div className="r-service-message"></div>
          </div>
        );
      } else {
        singularBetaServer = (
          <div className="status-row">
            <div className="r-service-name">{singularBetaLink}</div>
            <div className="r-service-status">
              <i className="fa fa-circle error" style={{ color: "red" }}></i>
            </div>
            <div className="r-service-message">
              {this.state.singularBetaStatus} - {this.state.singularBetaMessage}
            </div>
          </div>
        );
      }
    }

    let singularAppServer;
    if (this.state.singularAppStatus === null) {
      singularAppServer = (
        <div className="status-row">
          <div className="r-service-name">{singularAppLink}</div>
          <div className="r-service-status">
            <i className="fa fa-circle-o-notch fa-spin fa-fw pending"></i>
          </div>
          <div className="r-service-message"></div>
        </div>
      );
    } else {
      if (this.state.singularAppStatus === "OK") {
        singularAppServer = (
          <div className="status-row">
            <div className="r-service-name">{singularAppLink}</div>
            <div className="r-service-status">
              <i className="fa fa-circle success"></i>
            </div>
            <div className="r-service-message"></div>
          </div>
        );
      } else {
        globalStatus = "error";
        singularAppServer = (
          <div className="status-row">
            <div className="r-service-name">{singularAppLink}</div>
            <div className="r-service-status">
              <i className="fa fa-circle error" style={{ color: "red" }}></i>
            </div>
            <div className="r-service-message">
              {this.state.singularAppStatus} - {this.state.singularAppMessage}
            </div>
          </div>
        );
      }
    }

    let cloudflareStatus = this.state.cloudflareStatus;
    let cloudflareService, cloudFlare, cloudFlareServicesStatus;
    let cloudFlareComponents = {};
    if (cloudflareStatus === null || utils.isString(cloudflareStatus)) {
      cloudFlare = (
        <div className="status-row">
          <div className="r-service-name">{cloudflareLink}</div>
          <div className="r-service-status">
            <i className="fa fa-circle-o-notch fa-spin fa-fw pending"></i>
          </div>
          <div className="r-service-message"></div>
        </div>
      );
      cloudFlareServicesStatus = "pending";
    } else {
      cloudFlareComponents = cloudflareStatus.components;
      let _services = cloudflareStatus.components.service;

      let cloudFlareMajor = _services.find(function (item, index) {
        if (item.status === "major_outage") return item;
        return null;
      });
      let cloudFlarePartial = _services.find(function (item, index) {
        if (item.status === "partial_outage") return item;
        return null;
      });
      let cloudFlareWarning = _services.find(function (item, index) {
        if (item.status === "degraded_performance") return item;
        return null;
      });

      if (cloudFlareMajor) {
        cloudFlare = (
          <div className="status-row">
            <div className="r-service-name">{cloudflareLink}</div>
            <div className="r-service-status">
              <i className="fa fa-circle error" style={{ color: "red" }}></i>
            </div>
            <div className="r-service-message">Major Outage</div>
          </div>
        );
        cloudFlareServicesStatus = "error";
        globalStatus = "error";
      } else if (cloudFlarePartial) {
        cloudFlare = (
          <div className="status-row">
            <div className="r-service-name">{cloudflareLink}</div>
            <div className="r-service-status">
              <i className="fa fa-circle error" style={{ color: "red" }}></i>
            </div>
            <div className="r-service-message">Partial Outage</div>
          </div>
        );
        cloudFlareServicesStatus = "error";
        globalStatus = "error";
      } else if (cloudFlareWarning) {
        cloudFlare = (
          <div className="status-row">
            <div className="r-service-name">{cloudflareLink}</div>
            <div className="r-service-status">
              <i className="fa fa-circle warning"></i>
            </div>
            <div className="r-service-message">Degraded Performance</div>
          </div>
        );
        cloudFlareServicesStatus = "warning";
      } else {
        cloudFlare = (
          <div className="status-row">
            <div className="r-service-name">{cloudflareLink}</div>
            <div className="r-service-status">
              <i className="fa fa-circle success"></i>
            </div>
            <div className="r-service-message"></div>
          </div>
        );
        cloudFlareServicesStatus = "success";
      }

      if (this.state.showCloudFlareServicesDetails) {
        cloudflareService = _services.map(function (item, index) {
          let _statusStyle = "pending";
          if (item.status === "major_outage") {
            _statusStyle = "error";
          } else if (item.status === "partial_outage") {
            _statusStyle = "warning";
          } else if (item.status === "degraded_performance") {
            _statusStyle = "warning";
          } else if (item.status === "operational") {
            _statusStyle = "success";
            item.description = "Operational";
          }
          return (
            <div className="status-row" key={item.id}>
              <div className="r-service-name">{item.name}</div>
              <div className="r-service-status">
                <i className={`fa fa-circle ${_statusStyle}`}></i>
              </div>
              <div className="r-service-message">
                {item.description == null ? "" : item.description}
              </div>
            </div>
          );
        });
      }
    }

    let _renderCloudflareComponents = function (_components) {
      if (_components && _components.length > 0) {
        return _components.map(
          function (item, index) {
            if (this.state.showCloudFlareServicesDetails) {
              let _statusStyle = "pending";
              let _message;
              if (item.status === "major_outage") _statusStyle = "error";
              else if (item.status === "partial_outage") {
                _statusStyle = "warning";
                _message = "Re-route";
              } else if (item.status === "degraded_performance") {
                _statusStyle = "warning";
                _message = "Degraded performance";
              } else if (item.status === "operational")
                _statusStyle = "success";
              return (
                <div className="status-row" key={item.id}>
                  <div className="r-service-name">{item.name}</div>
                  <div className="r-service-status">
                    <i className={`fa fa-circle ${_statusStyle}`}></i>
                  </div>
                  <div className="r-service-message">
                    {item.description == null ? _message : item.description}
                  </div>
                </div>
              );
            }
          }.bind(this)
        );
      }
    }.bind(this);

    return (
      <div className="page-wrapper">
        <div className="page-header">
          <a href="https://app.singular.live" tabIndex="-1">
            {globalStatus === "error" && (
              <img
                src={"./images/StatusLogoRed.svg"}
                alt="logo-v2"
                className="logo"
              />
            )}
            {globalStatus !== "error" && (
              <img
                src={"./images/StatusLogoGreen.svg"}
                alt="logo-v2"
                className="logo"
              />
            )}
          </a>
          <a href="https://support.singular.live" tabIndex="-1">
            Help
          </a>
        </div>

        <div className="status-wrapper">
          <div className="status-table">
            <div className={`status-header ${globalStatus}`}>
              {globalStatus === "success" && (
                <span>
                  <i className="fa fa-check" /> All Services are operational
                </span>
              )}
              {globalStatus === "error" && (
                <span>
                  <i className="fa fa-exclamation" /> Some critical services are
                  not operational
                </span>
              )}
              {globalStatus === "warning" && (
                <span>
                  <i className="fa fa-exclamation" /> Some non-critical services
                  are not operational
                </span>
              )}
              {globalStatus === "pending" && (
                <span>
                  <i className="fa fa-circle-o-notch fa-spin fa-fw" /> Checking
                  services...
                </span>
              )}
            </div>
            <div className="status-body">
              <div className="status-row header">
                <div className="r-service-name">Singular Core</div>
                {this.state.busyAll === true && (
                  <div className="r-service-status">
                    <i className="fa fa-circle-o-notch fa-spin fa-fw pending" />
                  </div>
                )}
                {this.state.busyAll === false && (
                  <div className="r-service-status"></div>
                )}
                <div className="r-service-message"></div>
              </div>
              {singularAppServer}
              {firebase}
              {mediaStorageStatus}
              {cloudFlare}
              {dataStream}
              {dataStore}

              <div className="offset"></div>
              <div className="status-row header">
                <div className="r-service-name">Amazon S3 Services</div>
                {awsHeaderStatus === "success" && (
                  <div className="r-service-status">
                    <i className="fa fa-circle success" />
                  </div>
                )}
                {awsHeaderStatus === "pending" && (
                  <div className="r-service-status">
                    <i className="fa fa-circle-o-notch fa-spin fa-fw pending" />
                  </div>
                )}
                {awsHeaderStatus === "error" && (
                  <div className="r-service-status">
                    <i className="fa fa-circle error" />
                  </div>
                )}
                <div className="r-service-toggle">
                  <button
                    tabIndex="-1"
                    onClick={() => {
                      this.setState({
                        showAwsDetails: !this.state.showAwsDetails,
                      });
                    }}
                  >
                    {this.state.showAwsDetails === true && (
                      <i className="fa fa-chevron-down"></i>
                    )}
                    {this.state.showAwsDetails === false && (
                      <i className="fa fa-chevron-up"></i>
                    )}
                  </button>
                </div>
              </div>
              {aws}

              <div className="offset"></div>
              <div className="status-row header">
                <div className="r-service-name">Cloudflare Services</div>
                {cloudFlareServicesStatus === "success" && (
                  <div className="r-service-status">
                    <i className="fa fa-circle success" />
                  </div>
                )}
                {cloudFlareServicesStatus === "pending" && (
                  <div className="r-service-status">
                    <i className="fa fa-circle-o-notch fa-spin fa-fw pending" />
                  </div>
                )}
                {cloudFlareServicesStatus === "warning" && (
                  <div className="r-service-status">
                    <i className="fa fa-circle warning" />
                  </div>
                )}
                {cloudFlareServicesStatus === "error" && (
                  <div className="r-service-status">
                    <i className="fa fa-circle error" />
                  </div>
                )}
                <div className="r-service-toggle">
                  <button
                    tabIndex="-1"
                    onClick={() => {
                      this.setState({
                        showCloudFlareServicesDetails:
                          !this.state.showCloudFlareServicesDetails,
                      });
                    }}
                  >
                    {this.state.showCloudFlareServicesDetails === true && (
                      <i className="fa fa-chevron-down"></i>
                    )}
                    {this.state.showCloudFlareServicesDetails === false && (
                      <i className="fa fa-chevron-up"></i>
                    )}
                  </button>
                </div>
              </div>
              {cloudflareService}
              {_renderCloudflareComponents(cloudFlareComponents.asia)}
              {_renderCloudflareComponents(cloudFlareComponents.europe)}
              {_renderCloudflareComponents(cloudFlareComponents.africa)}
              {_renderCloudflareComponents(cloudFlareComponents.latin_america)}
              {_renderCloudflareComponents(cloudFlareComponents.middle_east)}
              {_renderCloudflareComponents(cloudFlareComponents.north_america)}
              {_renderCloudflareComponents(cloudFlareComponents.oceania)}

              <div className="offset"></div>
              <div className="status-row header">
                <div className="r-service-name">Non essential services</div>
              </div>
              {singularBetaServer}
              {/*singularAlphaServer*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SingularStatus;
